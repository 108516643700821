<template>
  <div class="main-container">
    <div v-if="!showPdf">
      <img v-if="false" src="" alt="" />
      <p class="rem36 c-333333 apply-address fNormal">上传凭证</p>
      <van-uploader
        v-model="fileList"
        multiple
        :deletable="false"
        class="mt20"
        :before-read="beforeRead"
        :after-read="compressFile"
      >
        <div class="uploader">
          <img :src="uploaderAddIcon" alt="" />
        </div>
        <template #preview-cover="{ file }">
          <div @click="showFiles(file)" class="showFiles">
            <div class="delImg">
              <img :src="uploaderDelIcon" alt="" @click.stop="delFile(file)" />
            </div>
          </div>
        </template>
      </van-uploader>
      <div class="mt10 rem28 c-666666">凭证支持的格式：jpeg、jpg、png、pdf</div>
      <div class="mt40">
        <van-button
          round
          block
          type="info"
          native-type="submit"
          :disabled="fileList.length == 0"
          @click="nextStep"
          >下一步</van-button
        >
      </div>
    </div>
    <div v-if="showPdf">
      <pdf v-for="i in numPages" :key="i" :src="src" :page="i" ref="myPdfComponent"></pdf>
      <div class="btn">
        <van-button round block type="info" @click="cancle">取消预览</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Button, Uploader, Toast, Dialog } from 'vant'

import { uploadImageInfoApi, getBorrowFaceTokenApi } from '@/apis/borrow'
import { getScanFaceUrlApi } from '@/apis/credit'
import pdf from 'vue-pdf'
import { mapState } from 'vuex'

import loadingIndex from '@/utils/overlay'
Vue.use(loadingIndex)

Vue.use(Button)
Vue.use(Uploader)
Vue.use(Toast)

export default {
  components: {
    pdf,
  },
  data() {
    return {
      fileList: [],
      uploaderAddIcon: require('@/assets/images/borrow/upload-addIcon.png'),
      pdfIcon: require('@/assets/images/borrow/pdf.png'),
      uploaderDelIcon: require('@/assets/images/borrow/upload-deleteIcon.png'),
      faceBackUrl: window.location.origin, // 刷脸成功返回地址
      uploadList: [],
      showPdf: false,
      src: '',
      numPages: 0,
      page: 1,
      currentPage: 0,
    }
  },
  created() {},
  computed: {
    ...mapState({
      loanNo: (state) => state.borrow.loanNo,
      applySeq: (state) => state.borrow.applySeq,
    }),
  },
  methods: {
    beforeRead(file) {
      var fileSize = parseFloat(parseInt(file.size) / 1024 / 1024).toFixed(2)
      if (
        file.type !== 'image/jpeg' &&
        file.type !== 'image/png' &&
        file.type !== 'image/jpg' &&
        file.type !== 'application/pdf'
      ) {
        Toast('请上传 jpg、png、jpeg、pdf 格式文件')
        return false
      }
      if (Number(fileSize) >= 10) {
        Dialog.confirm({
          title: '温馨提示',
          message: '上传文件太大，请重新上传。',
          confirmButtonText: '确定',
          cancelButtonText: false,
          type: 'warning',
        })
          .then(() => {})
          .catch(() => {})
        return
      }
      return true
    },

    delFile(file) {
      Dialog.confirm({
        title: '提示',
        message: '确认删除该文件？',
      })
        .then(() => {
          this.fileList.forEach((item, index) => {
            if (item.file.lastModified === file.lastModified) {
              this.fileList.splice(index, 1)
            }
          })
        })
        .catch(() => {})
    },
    showFiles(file) {
      if (file.type == 'application/pdf') {
        this.fileList.forEach((item, index) => {
          if (item.file.lastModified === file.lastModified) {
            this.src = pdf.createLoadingTask({ url: item.content })
            this.src.promise
              .then((pdf) => {
                this.numPages = pdf.numPages
              })
              .catch((err) => {
                console.log(err)
              })
            this.showPdf = true
          }
        })
      }
    },
    cancle() {
      this.showPdf = false
    },
    showloading() {
      var title = '加载中···'
      this.$showLoading({
        title: title,
      })
    },
    hideloading() {
      this.$cancelLoading()
    },
    photoCompress(file, w, objDiv) {
      let that = this
      var ready = new FileReader()
      /*开始读取指定的Blob对象或File对象中的内容. 当读取操作完成时,readyState属性的值会成为DONE,如果设置了onloadend事件处理程序,则调用之.同时,result属性中将包含一个data: URL格式的字符串以表示所读取文件的内容.*/
      ready.readAsDataURL(file.file)
      ready.onload = function () {
        var re = that.result
        that.canvasDataURL(file.content, w, objDiv)
      }
    },
    convertBase64UrlToBlob(urlData) {
      var arr = urlData.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },
    canvasDataURL(path, obj, callback) {
      var img = new Image()
      img.src = path
      img.onload = function () {
        var that = this
        // 默认按比例压缩
        var w = that.width,
          h = that.height,
          scale = w / h
        w = obj.width || w
        h = obj.height || w / scale
        var quality = 0.2 // 默认图片质量为0.7
        //生成canvas
        var canvas = document.createElement('canvas')
        var ctx = canvas.getContext('2d')
        // 创建属性节点
        var anw = document.createAttribute('width')
        anw.nodeValue = w
        var anh = document.createAttribute('height')
        anh.nodeValue = h
        canvas.setAttributeNode(anw)
        canvas.setAttributeNode(anh)
        ctx.drawImage(that, 0, 0, w, h)
        // 图像质量
        if (obj.quality && obj.quality <= 1 && obj.quality > 0) {
          quality = obj.quality
        }
        // quality值越小，所绘制出的图像越模糊
        var base64 = canvas.toDataURL('image/jpeg', quality)
        // 回调函数返回base64的值
        callback(base64)
      }
    },
    compressFile(file) {
      let that = this
      var fileSize = parseFloat(parseInt(file.file.size) / 1024 / 1024).toFixed(2)
      if (Number(fileSize) > 10) {
        that.$common.toast('上传图片太大，请重新拍照上传。')
        return
      }

      if (Number(fileSize) > 2 && file.file.type.indexOf('image') != '-1') {
        that.photoCompress(
          file,
          {
            quality: 0.2,
          },
          function (base64Codes) {
            //console.log("压缩后：" + base.length / 1024 + " " + base);
            var bl = that.convertBase64UrlToBlob(base64Codes)
            let newFile = new File([bl], file.file.name, { type: file.file.type.toLowerCase() })
            that.fileList[that.fileList.length - 1].file = newFile
          }
        )
      }
    },

    nextStep() {
      this.showloading()
      const formdata = new FormData()
      this.fileList.forEach((item) => {
        formdata.append('files', item.file)
      })
      formdata.getAll('files')
      formdata.append('loanNo', this.loanNo)
      uploadImageInfoApi(formdata)
        .then((res) => {
          if (res.code === 200 && res.data.isSuccess) {
            getBorrowFaceTokenApi({
              applySeq: this.applySeq,
              callbackUrl: this.faceBackUrl + '/face-result?type=borrow',
            })
              .then((result) => {
                if (result.code === 200) {
                  this.$store.commit('SET_FACE_TOKEN', result.data.faceToken)
                  getScanFaceUrlApi(result.data.faceToken)
                    .then((r) => {
                      this.hideloading()
                      window.location.href = r.data.originalUrl
                    })
                    .catch(() => {
                      this.hideloading()
                    })
                }
              })
              .catch(() => {
                this.hideloading()
              })
          } else {
            this.$common.toast(res.data.msg)
            this.hideloading()
          }
        })
        .catch(() => {
          this.hideloading()
        })
    },
  },
}
</script>
<style lang="less" scoped>
.uploader {
  width: 2rem;
  height: 2rem;
  background: #f2f4f7;
  border-radius: 0.06rem;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 0.36rem;
    height: 0.36rem;
  }
}
/deep/.van-uploader__preview-cover {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.06rem;
  background: rgba(0, 0, 0, 0.3);
}
.delImg {
  width: 0.6rem;
  height: 0.6rem;
  background: rgba(0, 0, 0, 0.355);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 0.22rem;
    height: 0.26rem;
    opacity: 1;
  }
}

/deep/ .van-uploader__preview-image,
/deep/ .van-uploader__upload {
  width: 2rem;
  height: 2rem;
}
/deep/ .van-uploader__input-wrapper {
  order: -1;
  margin-right: 0.16rem;
}
/deep/ .van-uploader__file {
  width: 2rem;
  height: 2rem;
  background-image: url('../../assets/images/borrow/pdf.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.showFiles {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn {
  position: fixed;
  width: 94%;
  left: 3%;
  bottom: 0.4rem;
}
</style>
